<template>
    <div class="time-period">
      <div class="page-header">
        <h1>{{ $t('Marketing Management')}} > {{ $t('Time Period') }} > {{ $t('Add') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Add Time Period') }}</h2>
        </div>
        <TimePeriodForm @action="addTime"/>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import Common from '@/lib/common';
  import Time from '@/lib/time';
  import TimePeriodForm from '@/components/marketing/TimePeriodForm.vue';
  
  export default {
    name: 'CreateTimePeriod',
    components: {
        TimePeriodForm
    },
    methods:{
      async addTime(form){
        try{
          const loginInfo = Common.getLoginInfo();
          const time = await Time.createTime(this.apiUrl, form, loginInfo);
          this.$router.push('/time-period');
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: mapState({
     langcode: state => state.langcode,
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>